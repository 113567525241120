import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
/*import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';*/

export class PhotoService {
    public photos: Photo[] = [];
    public imageUrl: string | undefined;

    public async addNewToGallery() {
        const capturedPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100
        });

        this.imageUrl = capturedPhoto.webPath;
        console.log('imageUrl', this.imageUrl);

        /*this.photos.unshift({
            filepath: "soon...",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            webviewPath: capturedPhoto.webPath!,
        });*/
    }
}

export interface Photo {
    filepath: string;
    webviewPath: string;
}

