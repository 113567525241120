import Axios from '../services/Axios';
import { FamilyList } from '../common/models/FamilyList';
import { FamilyCampusList } from '../common/models/FamilyCampusList';
import { FamilyAuthorizedList } from '../common/models/FamilyAuthorizedList';
import { FamilyChildrenCampusList } from '../common/models/FamilyChildrenCampusList';

export default {
    async getFamily(id: any){
        const response = await Axios.get(`/mobile/family/${id}`);
        return response.data;
    },

    async getFamilyCampus(id: any){
        const response = await Axios.get(`/mobile/family/${id}/campus/list`);
        return response.data.data;
    },

    async getFamilyAuthorized(id: any){
      const response = await Axios.get(`/mobile/family/${id}/authorized/list`);
      return response.data.data;
    },

    async getFamilyChildrenCampus(id: any){
      const response = await Axios.get(`/mobile/family/${id}/children/list`);
      return response.data.data;
    },

    async getFamilyBadges(id: any){
      const response = await Axios.get(`/mobile/family/${id}/badges`);
      return response.data;
    },

    async finishRegistration(id: any, family: any) {
        console.log('family', family);

        const formData = new FormData();
        formData.append('data', family);

        let response = null;
        try {
            response = await Axios.put(`/mobile/family/${id}`, formData);
        } catch(e) {
            console.log(e);
            console.error(e.response.data.message);
            return e.response.data;
        }
        return response.data;
    },

};
