<template>
  <ion-page onload="noBack()">

    <ion-header>
      <ion-toolbar>
        <ion-title>INGRESSIFY</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen class="ion-padding">
    <!-- Card Informacion -->
      <img :src="require('@/images/fotofamilia.png')">

    <ion-card>
      <ion-card-header>
        <ion-card-title>{{ alias }}</ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <ion-grid>
          <ion-row>
            <!-- El nombre de la familia viene del servicio, no cambia -->

            <!-- El nombre de usuario viene del servicio, no cambia -->
            <ion-col size="12">
              <h1>Responsable: {{ nameResponsible }}</h1>
              <br>
              <h1>Contacto: {{ contactResponsible }}</h1>
            </ion-col>
            <ion-col>
              <ion-row>
                <ion-col>
                  <img id="imgResponsable" style="width: 60px;border-radius: 40%;">
                </ion-col>
                <ion-col>
                  <ion-item>
                    <ion-label style="margin: 5px" class="custom-file-upload" for="fileResponsable">
                      <img style="margin-left:25px; width: 35px;" :src="require('@/icons/camera.svg')">
                      <br>
                      Elegir Foto
                    </ion-label>
                    <ion-input type="file" name="fileResponsable" id="fileResponsable" accept='image/*' v-model="addPhotoAuthorized" @change="changeImgResponsable($event)">
                    </ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col size="12"><br></ion-col>
            <ion-col size="12">
              <h1><strong>HIJOS</strong></h1>
              <!-- <ion-button v-if="btnAddChild" expand="block" @click="addChild">Agregar +</ion-button> -->
              <br>
              <div v-for="item in childsAdd" v-bind:key="item">
                <ion-row>
                  <ion-col>
                    <img style="width: 60px;border-radius: 40%;" :src="item.hijophoto" alt=".">
                  </ion-col>
                  <ion-col>
                    <h1 style="margin-top: 20px">{{ item.firstname }}</h1>
                  </ion-col>
                </ion-row>
                <hr class="solid">
              </div>

              <div v-if="btnAddChild">
                <div v-for="item in childsQuantity" v-bind:key="item">
                  <ion-row>
                    <ion-col>
                      <img style="width: 50px;border-radius: 40%;" :src="require('@/images/user.png')">
                    </ion-col>
                    <ion-col>
                      <ion-button expand="block" @click="addChild">Agregar +</ion-button>
                    </ion-col>
                  </ion-row>
                </div>
              </div>

              <div v-if="addChildSection">
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input type="text" v-model="addNameChild"></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">Apellidos</ion-label>
                    <ion-input type="text" v-model="addLastNameChild"></ion-input>
                  </ion-item>
                </ion-col>
                <br><br>
                <ion-col size="12">
                  <ion-label>Escuela</ion-label>
                  <ion-item>
                    <ion-select @ionChange="selectSchoolAdd" placeholder="Seleccione una escuela">
                      <ion-select-option
                          v-for="item in escuelas"
                          v-bind:value="{ id: item.id, name: item.name }"
                          v-bind:key="item">
                        {{ item.name }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>
                <ion-col size="12">
                  <ion-item>
                    <ion-label style="margin: 5px" class="custom-file-upload" for="fileHijo">
                      <img style="margin-left:25px; width: 35px;" :src="require('@/icons/camera.svg')">
                      <br>
                      Elegir Foto
                    </ion-label>
                    <ion-input id="fileHijo" type="file" accept='image/*' v-model="addPhotoAuthorized" @change="changeImgHijo($event)"></ion-input>
                  </ion-item>
                  <img style="height: 30%" id="imgHijo">
                </ion-col>
                <br>
                <ion-button expand="block" @click="finishAddChild">Agregar</ion-button>
                <br>
                <ion-button color="medium" expand="block" @click="closeAddChild">Cancelar</ion-button>
              </div>

            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-content>
          <ion-row>
            <ion-col><br></ion-col>
            <ion-col size="12">
              <h1><strong>AUTORIZADOS</strong></h1>
              <!-- <ion-button v-if="btnAddAuthorized" expand="block" @click="addAuthorized">Agregar +</ion-button> -->
              <br>
              <div v-for="item in authorizedAdd" v-bind:key="item">
                <ion-row>
                  <ion-col>
                    <img style="width: 60px;border-radius: 40%;" :src="item.authPhoto" alt=".">
                  </ion-col>
                  <ion-col>
                    <h1 style="margin-top: 20px">{{ item.firstname }}</h1>
                  </ion-col>
                </ion-row>
                <hr class="solid">
            </div>

              <div v-if="addAuthorizedSection">
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">Nombre</ion-label>
                    <ion-input type="text" v-model="addNameAuthorized"></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">Apellidos</ion-label>
                    <ion-input type="text" v-model="addLastNameAuthorized"></ion-input>
                  </ion-item>
                </ion-col>
                <br><br>
                <ion-col size="12">
                  <ion-item>
                    <ion-label style="margin: 5px" class="custom-file-upload" for="fileAuthorized">
                      <img style="margin-left:25px; width: 35px;" :src="require('@/icons/camera.svg')">
                      <br>
                      Elegir Foto
                    </ion-label>
                    <ion-input id="fileAuthorized" type="file" accept='image/*' v-model="addPhotoAuthorized" @change="changeImgAuthorized($event)"></ion-input>
                  </ion-item>
                  <img style="height: 30%" id="imgAutorizado">
                </ion-col>
                <br>
                <ion-button expand="block" @click="finishAddAuthorized">Agregar</ion-button>
                <br>
                <ion-button color="medium" expand="block" @click="closeAddAuthorized">Cancelar</ion-button>
              </div>
              <ion-button v-if="btnAddAuthorized" expand="block" @click="addAuthorized">Agregar +</ion-button>

            </ion-col>
          </ion-row>
      </ion-card-content>
    </ion-card>

    <!-- Card Añadir hijos
    <ion-card v-if="addChildSection">

      <ion-card-content>
        <div>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Nombre</ion-label>
              <ion-input type="text" v-model="addNameChild"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Apellidos</ion-label>
              <ion-input type="text" v-model="addLastNameChild"></ion-input>
            </ion-item>
          </ion-col>
          <br><br>
          <ion-col size="12">
            <ion-label>Escuela</ion-label>
            <ion-item>
              <ion-select @ionChange="selectSchoolAdd" placeholder="Seleccione una escuela">
                <ion-select-option
                    v-for="item in escuelas"
                        v-bind:value="{ id: item.id, name: item.name }"
                        v-bind:key="item">
                      {{ item.name }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-input id="fileHijo" type="file" accept='image/*' v-model="addPhotoAuthorized" @change="changeImgHijo($event)"></ion-input>
            <img style="height: 30%" id="imgHijo">
          </ion-col>
          <br>
          <ion-button expand="block" @click="finishAddChild">Agregar</ion-button>
          <br>
          <ion-button color="medium" expand="block" @click="closeAddChild">Cancelar</ion-button>
        </div>

      </ion-card-content>
    </ion-card> -->

    <!-- Card Añadir personas autorizadas
    <ion-card v-if="addAuthorizedSection">

      <ion-card-content>
        <div>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Nombre</ion-label>
              <ion-input type="text" v-model="addNameAuthorized"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item>
              <ion-label position="floating">Apellidos</ion-label>
              <ion-input type="text" v-model="addLastNameAuthorized"></ion-input>
            </ion-item>
          </ion-col>
          <br><br>
          <ion-col size="12">
            <ion-input id="fileAuthorized" type="file" accept='image/*' v-model="addPhotoAuthorized" @change="changeImgAuthorized($event)"></ion-input>
            <img style="height: 30%" id="imgAutorizado">
          </ion-col>
          <br>
          <ion-button expand="block" @click="finishAddAuthorized">Agregar</ion-button>
          <br>
          <ion-button color="medium" expand="block" @click="closeAddAuthorized">Cancelar</ion-button>
        </div>
      </ion-card-content>
    </ion-card> -->

    <ion-button expand="block" @click="completeRegistration">Terminar</ion-button>
    <br>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonToolbar, IonCol, IonGrid, IonRow, IonPage, IonCardContent, IonCardHeader,
  IonCardTitle, IonItem, IonSelect, IonSelectOption, IonInput, toastController, alertController, loadingController
} from '@ionic/vue';
import router from "../router";
import { TokenUtils } from '@/utils/TokenUtils';
import FamilyService from "@/services/FamilyService";
import { PhotoService } from '../services/photo.service';

export default {
  name: "finishRegistration",
  components: {
    IonToolbar, IonCol, IonGrid, IonRow, IonPage, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonSelect,
    IonSelectOption, IonInput
  },
  data: () => ({
    photoService: PhotoService,
    img: null,
    familyId: null,
    tokenDecode: null,
    alias: null,
    email: 'correo@email.com',
    addChildSection: 0,
    addAuthorizedSection: 0,
    btnAddChild: 1,
    btnAddAuthorized: 1,
    addNameChild: '',
    addLastNameChild: '',
    schoolChild: null,
    addNameAuthorized: '',
    addLastNameAuthorized: '',
    addPhotoAuthorized: null,
    file: null,
    base64Authorized: null,
    childsAdd: [],
    authorizedAdd: [],
    familyData: null,
    nameResponsible: null,
    contactResponsible: null,
    imgHijo: null,
    imgResponsable: null,
    arrayimgchild: [],
    arrayimgauth: [],
    typeImgResponsable: null,
    imgRespFlag: 0,
    childsQuantity: 0,
    escuelas: [
      {
        "number": 1,
        "name": "Escuela 1"
      },
      {
        "number": 2,
        "name": "Escuela 2"
      },
      {
        "number": 3,
        "name": "Escuela 3"
      },
    ],
  }),
  methods: {
    addPhotoToGallery() {
      this.photoService.prototype.addNewToGallery();
    },
    noBack() {
      console.log('noback');
      window.location.hash="no-back-button";
      window.location.hash="Again-No-back-button" //chrome
      window.onhashchange=function(){window.location.hash="no-back-button";}
    },
    addChild() {
      this.btnAddChild = 0;
      this.addChildSection = 1;
      this.childsQuantity--;
    },
    selectSchoolAdd(event) {
      this.schoolChild = event.detail.value;
    },
    async finishAddChild() {
      console.log(this.schoolChild);
      if (this.addNameChild === '') {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de llenar e nombre del niño',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(localStorage.getItem("arrayimgchild") === null) {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de seleccionar una foto para el niño, o la imagen seleccionada sobrepasa el tamaño permitido',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(this.schoolChild === null) {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de elegir la escuela',
          buttons: ['Ok']
        });
        await alert.present();
      } else {
        const child = {
          firstname: this.addNameChild + ' ' + this.addLastNameChild,
          // eslint-disable-next-line @typescript-eslint/camelcase
          campus_id: this.schoolChild.id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          family_id: this.familyId,
          photo: {
            type: localStorage.getItem('typeimgchild'),
            content: localStorage.getItem('arrayimgchild').replace(/^data:.+;base64,/, ''),
          },
          //photo: localStorage.getItem('imgBase64Hijo'),
          hijophoto: localStorage.getItem("arrayimgchild"),
        };

        this.childsAdd.push(child);
        console.log('childsAdd', this.childsAdd);

        if (this.childsAdd.length === this.familyData.data.child_quantity) {
          this.btnAddChild = 0;
          this.addChildSection = 0;
        } else {
          this.btnAddChild = 1;
          this.addChildSection = 0;
        }
        const output1 = document.getElementById('imgHijo');
        if(output1 !== null) {
          output1.src = '';
        }
        this.addNameChild = '';
        this.addLastNameChild = '';
        localStorage.removeItem('imgBase64Hijo');
        console.log('remove', localStorage.getItem("imgBase64Hijo"));
        localStorage.clear();
      }
    },
    addAuthorized() {
      this.btnAddAuthorized = 0;
      this.addAuthorizedSection = 1;
    },
    changeImgResponsable(event) {
      this.file = document.querySelector('#fileResponsable > input[type="file"]').files[0];
      localStorage.setItem('typeimgresponsable', this.file.type);
      const reader = new FileReader();
      reader.onload = function() {
        this.img = reader.result.replace(/^data:.+;base64,/, '');
        localStorage.setItem("imgBase64Resp", this.img);
        const output = document.getElementById('imgResponsable');
        output.src = reader.result;
      }
      reader.readAsDataURL(this.file);
      this.typeImgResponsable = localStorage.getItem('typeimgresponsable');
    },
    changeImgHijo(event) {
      if(localStorage.getItem("imgBase64Resp") !== null || this.imgRespFlag !== 1){
        console.log('entro');
        this.imgResponsable = localStorage.getItem("imgBase64Resp");
        console.log('imgResponsable', this.imgResponsable);
        localStorage.clear();
        this.imgRespFlag = 1;
      }
      this.file = document.querySelector('#fileHijo > input[type="file"]').files[0];
      localStorage.setItem('typeimgchild', this.file.type);
      const reader = new FileReader();
      reader.onload = function() {
        this.img = reader.result.replace(/^data:.+;base64,/, '');
        //localStorage.setItem("imgBase64Hijo", this.img);
        const output = document.getElementById('imgHijo');
        output.src = reader.result;
        localStorage.setItem('arrayimgchild', reader.result);
      }
      reader.readAsDataURL(this.file);
      //const imgBase64Hijo = localStorage.getItem("imgBase64Hijo");
      //this.imgHijo = imgBase64Hijo;
      this.arrayimgchild = localStorage.getItem("arrayimgchild");
      //localStorage.removeItem("arrayimgchild");
      //localStorage.removeItem("imgBase64Hijo");
    },
    changeImgAuthorized(event) {
      this.file = document.querySelector('#fileAuthorized > input[type="file"]').files[0];
      localStorage.setItem('typeimgauth', this.file.type);
      const reader = new FileReader();
      reader.onload = function() {
        this.img = reader.result.replace(/^data:.+;base64,/, '');
        //localStorage.setItem("imgBase64Auth", this.img);
        const output = document.getElementById('imgAutorizado');
        output.src = reader.result;
        localStorage.setItem('arrayimgauth', reader.result);
      }
      reader.readAsDataURL(this.file);
      this.arrayimgauth = localStorage.getItem("arrayimgauth");
      console.log('arrayimgauth', this.arrayimgauth);
      //localStorage.removeItem("arrayimgauth");
    },
    async finishAddAuthorized() {
      if (this.addNameAuthorized === '') {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de llenar el nombre de la persona',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(this.addLastNameAuthorized === '') {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de llenar el apellido de la persona',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(localStorage.getItem("arrayimgauth") === null) {
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de seleccionar una foto para la persona, o la imagen seleccionada sobrepasa el tamaño permitido',
          buttons: ['Ok']
        });
        await alert.present();
      } else {
        const authorized = {
          firstname: this.addNameAuthorized,
          lastname: this.addLastNameAuthorized,
          photo: {
            type: localStorage.getItem('typeimgauth'),
            content: localStorage.getItem("arrayimgauth").replace(/^data:.+;base64,/, ''),
          },
          //photo: localStorage.getItem("imgBase64Auth"),
          authPhoto: localStorage.getItem("arrayimgauth"),
          // eslint-disable-next-line @typescript-eslint/camelcase
          person_type: 0
        };

        this.authorizedAdd.push(authorized);
        console.log('authorizedAdd', this.authorizedAdd);

        this.btnAddAuthorized = 1;
        this.addAuthorizedSection = 0;
        const output2 = document.getElementById('imgAutorizado');
        if(output2 !== null) {
          output2.src = '';
        }
        this.addNameAuthorized = '';
        this.addLastNameAuthorized = '';
        localStorage.removeItem('imgBase64Auth');
        console.log('remove', localStorage.getItem("imgBase64Auth"));
        localStorage.clear();
      }
    },
    closeAddChild() {
      const output1 = document.getElementById('imgHijo');
      if(output1 !== null) {
        output1.src = '';
      }
      this.addNameChild = '';
      this.addLastNameChild = '';
      localStorage.removeItem('imgBase64Hijo');
      console.log('remove', localStorage.getItem("imgBase64Hijo"));

      this.btnAddChild = 1;
      this.addChildSection = 0;
    },
    closeAddAuthorized() {
      const output2 = document.getElementById('imgAutorizado');
      console.log(output2);
      output2.src = '';
      this.addNameAuthorized = '';
      this.addLastNameAuthorized = '';
      localStorage.removeItem('imgBase64Auth');
      console.log('remove', localStorage.getItem("imgBase64Auth"));

      this.btnAddAuthorized = 1;
      this.addAuthorizedSection = 0;
    },
    async completeRegistration() {
      console.log('imgResponsable', this.imgResponsable);
      const loading = await loadingController.create({
        message: 'Please wait...',
        duration: 90000
      });
      await loading.present();
      if(this.imgResponsable === null) {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de elegir una foto para el responsable',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(this.childsAdd.length !== this.familyData.data.child_quantity) {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de completar el registro de todos los niños',
          buttons: ['Ok']
        });
        await alert.present();
      } else if(this.authorizedAdd.length === 0) {
        setTimeout(function() {
          loading.dismiss()
        }, this.timeout);
        const alert = await alertController.create({
          header: 'Error',
          message: 'Favor de registrar al menos una persona responsable',
          buttons: ['Ok']
        });
        await alert.present();
      } else {
        const finishRegistration = {
          alias: this.alias,
          username: "Fam@acs.com",
          password: "test",
          // eslint-disable-next-line @typescript-eslint/camelcase
          responsible_contact: "8181818181",
          // eslint-disable-next-line @typescript-eslint/camelcase
          child_quantity: this.familyData.data.child_quantity,
          responsible: {
            photo: {
              type: this.typeImgResponsable,
              content: this.imgResponsable,
            }
            //photo: localStorage.getItem("imgBase64Resp")
          },
          children: this.childsAdd,
          // eslint-disable-next-line @typescript-eslint/camelcase
          authorized_persons: this.authorizedAdd,
        };
        console.log('finishRegistration', finishRegistration);
        const finishRegistrationJson = JSON.stringify(finishRegistration);

        const resp = await FamilyService.finishRegistration(this.familyId, finishRegistrationJson);
        console.log('resp', resp);

        if (resp.data != null || resp.data != undefined) {
          setTimeout(function() {
            loading.dismiss()
          }, this.timeout);
          router.push(`/menu/?token=${this.$route.query.token}`);
          localStorage.removeItem('imgBase64Auth');
          localStorage.removeItem('imgBase64Resp');
          localStorage.removeItem('imgBase64Hijo');
          localStorage.removeItem('arrayimgchild');
          localStorage.removeItem('arrayimgauth');
          localStorage.clear();
          this.imgRespFlag = 0;
        } else {
          setTimeout(function() {
            loading.dismiss()
          }, this.timeout);
          const alert = await alertController.create({
            header: 'Error',
            message: 'Error al terminar el registro',
            buttons: ['Ok']
          });
          await alert.present();
        }
      }
    },
  },
  async mounted() {
    const token = this.$route.query.token
    TokenUtils.setToken(token);
    this.tokenDecode = TokenUtils.getJwtData();
    console.log('tokenDecode', this.tokenDecode);
    this.alias = this.tokenDecode.alias;
    this.familyId = this.tokenDecode.sub;

    this.familyData = await FamilyService.getFamily(this.familyId);
    console.log('familyData', this.familyData.data);
    this.nameResponsible = this.familyData.data.responsible.firstname + ' ' + this.familyData.data.responsible.lastname;
    this.contactResponsible = this.familyData.data.responsible_contact;
    this.childsQuantity = this.familyData.data.child_quantity;

    this.escuelas = await FamilyService.getFamilyCampus(this.familyId);
    console.log('escuelas', this.escuelas);

    localStorage.removeItem('imgBase64Auth');
    localStorage.removeItem('imgBase64Resp');
    localStorage.removeItem('imgBase64Hijo');
    localStorage.removeItem('arrayimgchild');
    localStorage.removeItem('arrayimgauth');
    localStorage.clear();
  },
}
</script>

<style scoped>

hr.solid {
  border-top: 3px solid #bbb;
}

#fileResponsable{
  position: absolute;
  opacity: 0;
}
#fileHijo{
  position: absolute;
  opacity: 0;
}
#fileAuthorized{
  position: absolute;
  opacity: 0;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 8px;
  cursor: pointer;
}

/*.custom-input-file {
  background-color: #941B80;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 0;
  min-height: 15px;
  overflow: hidden;
  padding: 10px;
  position: relative;
  text-align: center;
  width: 400px;
}

.custom-input-file .input-file {
  border: 10000px solid transparent;
  cursor: pointer;
  font-size: 10000px;
  margin: 0;
  opacity: 0;
  outline: 0 none;
  padding: 0;
  position: absolute;
  right: -1000px;
  top: -1000px;
}*/

</style>
